<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">色区</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <router-link tag="div" to="/topicCircle" class="ht flex-between">
          <div class="left-ht">话题圈</div>
          <div class="right-ht"></div>
        </router-link>
        <div class="ht-img flex">
          <router-link tag="div" :to="{path:'topicCircleInfo',query:{name:item.name}}" class="img" v-for="(item,index) in topiclist" :key="index">
            <ImgDecypt class="img-ba" :src="item.cover"/>
            <div class="sec flex-column">
              <div class="sec-top"><span class="htq"></span>{{item.name}}</div>
              <div class="sec-bottom">{{item.desc}}</div>
            </div>
          </router-link>
        </div>
        <div class="list">
          <div class="title"><span class="title-w" :class="{active:!introduceShow}" @click="newest">最新</span><span class="title-w" :class="{active:introduceShow}" @click="introduce">推荐</span></div>
          <transition name="slide-r">
            <div class="list-all" v-if="introduceShow">
              <pullrefresh :disabled="true" @onLoad="onLoad('recommend')" ref="pull" v-show="videoList.length>0">
                <div v-for="(item,index) in videoList" :key="item.id">
                  <dynamiclist :item="item" @cared="cared" @shareShow="shareS(item,index)" @commentShow="commentS(item,index)" @membershipShow="memberShow(item)"/>
                  <AdvSwiper :advList="advList" v-if="(index + 1) % 4 == 0 && advList.length > 0" class="l_adv"/>
                </div>
              </pullrefresh>
              <van-loading type="spinner" color="#f21313" v-show="videoList&&videoList.length==0 && !noData"/>
              <nodata v-show="noData"/>
            </div>
          </transition>
          <transition name="slide-l">
            <div class="list-all" v-if="!introduceShow">
              <pullrefresh :disabled="true" @onLoad="onLoad('newest')" ref="pull" v-show="newestList.length>0">
                <div v-for="(item,index) in newestList" :key="item.id">
                  <dynamiclist :item="item"  @cared="cared" @shareShow="shareS(item,index)" @commentShow="commentS(item,index)" @membershipShow="memberShow(item)"/>
                  <AdvSwiper :advList="advList" v-if="(index + 1) % 4 == 0 && advList.length > 0" class="l_adv"/>
                </div>
              </pullrefresh>
              <van-loading type="spinner" color="#f21313" v-show="newestList&&newestList.length==0 && !noData"/>
              <nodata v-show="noData"/>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <router-link tag="div" to="/sendImg" class="send"></router-link>
    <comment v-show="commentShow" :topicInfo="memberInfo" @commentSuccess="commentSuccess" :commentShow="commentShow" @commentClose="commentShow=false"/>
    <membership v-show="membershipShow" :topicInfo="memberInfo" :membershipShow="membershipShow" @membershipClose="membershipShow=false"/>
    <share v-if="shareShow" :topicInfo="memberInfo" @shareSuccess="shareSuccess" :shareShow="shareShow" @shareClose="shareShow=false"/>
  </div>
</template>
<script>
import pullrefresh from "@/components/PullRefresh/index.vue";
import comment from '../widget/comment.vue';
import membership from '../widget/membership';
import share from '../widget/share.vue';
import dynamiclist from '../widget/dynamicList.vue';
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import {getTopiclist,sequMedia} from '@/api/app.js';
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  name:'seArea',
  components:{
    dynamiclist,
    comment,
    pullrefresh,
    membership,
    share,
    AdvSwiper
  },
  data(){
    return{
      commentShow:false,
      topiclist:[],//话题圈列表
      pageNum:1,//帖子列表页码
      videoList:[],//推荐帖子列表
      newestList:[],//最新帖子列表
      noData:false,//暂无数据
      introduceShow:false,//推荐模块显示
      index:0,//话题选中的项
      memberInfo:{},//开通会员弹窗需要的信息
      membershipShow:false,//是否显示开通会员弹窗
      shareShow:false,//分享组件的显示与否
      advList:[]
    }
  },
  created(){
    this.advList = getAdItem(AdType.seArea);
    this.getTopiclist();
    this.sequMedia('newest');//推荐帖子
  },
  methods:{
    cared(id){//博主关注成功回调 将图文列表的该作者的所有动态的关注状态改变
      if(this.introduceShow){
        this.videoList.forEach((item) => {
          if(item.author.id == id){
            item.author.cared = true;//改变该博主的关注状态
          }
        })
      }else{
        this.newestList.forEach((item) => {
          if(item.author.id == id){
            item.author.cared = true;//改变该博主的关注状态
          }
        })
      }
    },
    memberShow(item){//监听图片的点击事件
      this.membershipShow = true;
      this.memberInfo = item;
    },
    shareS(item,index){//监听分享点击事件
      this.index = index;
      this.shareShow = true;
      this.memberInfo = item;
    },
    shareSuccess(){//分享成功回调
      this.videoList[this.index].shareNum ++;
    },
    commentS(item,index){//监听评论点击事件
      this.index = index;
      this.commentShow = true;
      this.memberInfo = item;
    },
    commentSuccess(){//评论成功评论条数加一
      if(this.introduceShow){
        this.videoList[this.index].commentNum ++;
      }else{
        this.newestList[this.index].commentNum ++;
      }
    },
    introduce(){//点击推荐按钮
      if(!this.introduceShow){
        this.introduceShow = true;
        this.pageNum = 1;
        this.noData = false;
        this.videoList = [];
        this.sequMedia('recommend');//推荐帖子
      }
    },
    newest(){//点击最新按钮
      if(this.introduceShow){
        this.pageNum = 1;
        this.noData = false;
        this.newestList = [];
        this.introduceShow = false;
        this.sequMedia('newest');//最新帖子
      }
    },
    async sequMedia(type){//获取帖子列表
      let req = {
        pageNum:this.pageNum,
        pageSize:10,
        type:type
      }
      let ret = await this.$Api(sequMedia,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        let list = ret.data?ret.data:[]
        if(this.introduceShow){
          this.videoList = this.videoList.concat(list);
        }else{
          this.newestList = this.newestList.concat(list);
        }
        if(list.length < 10){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    onLoad(type){
      this.pageNum ++;
      this.sequMedia(type);//推荐帖子
    },
    async getTopiclist(){//获取话题列表
      let req = {
        pageNum:1,
        pageSize:10,
      }
      let ret = await this.$Api(getTopiclist,req);
      if(ret.code == 200){
        let list = ret.data.topicInfos ? ret.data.topicInfos : [];
        this.topiclist = list;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  color:#ffffff;
  font-size:14px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.list{
  position: relative;
}
.list-all{
  position: absolute;
  width:100%;
}
.l_adv{
  padding-top: 10px;
  height: 100px;
  overflow: hidden;
  /deep/ .swiperSlide {
    height: 100px;
  }
}
.ht{
  margin:10px 0 11px;
  font-size:16px;
  font-weight:bold;
  align-items: center;
}
.right-ht{
  width:9.4px;
  height:13.8px;
  background:url('../../assets/png/go_black.png') center center no-repeat;
  background-size:100%;
}
.ht-img{
  overflow-x:auto;
}
.img{
  margin-right:13px;
  width:179px;
  height:106px;
  border-radius: 4px;
  overflow:hidden;
  position: relative;
  color:#ffffff;
  flex-shrink: 0;
}
.sec{
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba($color: #000000, $alpha:0.4);
  align-items: center;
  justify-content: center;
}
.sec-top{
  font-size:13px;
}
.sec-bottom{
  margin-top:9px;
  font-size:10px;
}
.img-ba{
  width:100%;
  height:100%;
}
.title{
  margin:15px 0 10px;
}
.title-w{
  color:#f21318;
  font-size:15px;
  margin-right:20px;
}
.active{
  color:#ffffff;
  padding:2px 10px;
  background:$vermillion;
  border-radius:15px;
}
.htq{
  display: inline-block;
  width:22px;
  height:22px;
  background:url('../../assets/png/ht_quan.png') center center no-repeat;
  background-size:100%;
  margin-right:2px;
  vertical-align: -6px;
}
.send{
  position: fixed;
  right:22px;
  bottom:30px;
  width:60px;
  height:60px;
  background:url('../../assets/png/send.png') center center no-repeat;
  background-size:100%;
}
</style>
